import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

const NavigationItem = ({ active, item }) => (
  <li
    className={classNames('navigation-item', {
      'navigation-item--spacing': !item.button
    })}
  >
    {!item.button ? (
      <a
        className={classNames('navigation-item__link', {
          'navigation-item__link--active': active,
          'navigation-item__link--inactive': !active
        })}
        href={item.path}
        title={item.label}
      >
        {item.label}
      </a>
    ) : (
      <a
        className={classNames('button button-navigation', {
          'button--primary-cherry': item.button === 'primary',
          'button--secondary-white': item.button !== 'primary'
        })}
        href={item.path}
        title={item.label}
      >
        {item.label}
      </a>
    )}
  </li>
)

NavigationItem.propTypes = {
  active: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    button: PropTypes.string,
    label: PropTypes.string,
    path: PropTypes.string
  }).isRequired
}

export default NavigationItem
