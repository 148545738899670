import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import I18n from "i18n-js";
import classNames from "classnames";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import BurgerButton from "./BurgerButton";
import NavigationList from "./NavigationList";

const AppNavigation = ({
  primaryItems,
  secondaryItems,
  showBurgerMenu,
  title,
}) => {
  const [_burgerMenuVisible, _setBurgerMenuVisibility] = useState(false);
  const [_transparent, _setTransparency] = useState(true);

  const _updateTransparencyOnScroll = () => {
    if (window.pageYOffset > 10) {
      if (_transparent) {
        _setTransparency(false);
      }
    } else {
      if (!_transparent) {
        _setTransparency(true);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", _updateTransparencyOnScroll);

    return () => {
      window.removeEventListener("scroll", _updateTransparencyOnScroll);
    };
  }, [_transparent]);

  return (
    <nav
      className={classNames("navigation", {
        "navigation--expanded": _burgerMenuVisible,
        "navigation--collapsed": !_burgerMenuVisible,
        "navigation--transparent": _transparent,
        "navigation--opaque": !_transparent,
      })}
    >
      <div className="navbar">
        <a href="/" className="navbar__logo">
          Impact Score® Shopping
        </a>

        <TransitionGroup>
          {!_transparent && title ? (
            <CSSTransition classNames="fadein" timeout={400}>
              <div className="navbar__title hide-on-mobile">{title}</div>
            </CSSTransition>
          ) : null}
        </TransitionGroup>

        <div className="navbar__right">
          {showBurgerMenu ? (
            <BurgerButton
              onClick={() => _setBurgerMenuVisibility(!_burgerMenuVisible)}
            />
          ) : null}
        </div>
      </div>

      <TransitionGroup>
        {showBurgerMenu && _burgerMenuVisible && (
          <CSSTransition classNames="bouncedown" timeout={400}>
            <div className="navigation__wrapper">
              <div className="navigation__content">
                <NavigationList
                  primaryItems={primaryItems}
                  secondaryItems={secondaryItems}
                />
              </div>
            </div>
          </CSSTransition>
        )}
      </TransitionGroup>
    </nav>
  );
};

AppNavigation.defaultProps = {
  showBurgerMenu: true,
  title: null,
};

AppNavigation.propTypes = {
  primaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  secondaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
    })
  ),
  showBurgerMenu: PropTypes.bool,
  title: PropTypes.string,
};

export default AppNavigation;
