import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const Carousel = ({ animationDelay, items }) => {
  const _elementRef = useRef()

  const [_currentIndex, _setCurrentIndex] = useState(0)
  const [_elementWidth, _setElementWidth] = useState(0)
  const [_initialSwipePosition, _setInitialSwipePosition] = useState(0)
  const [_swiping, _setSwiping] = useState(false)
  const [_swipePosition, _setSwipePosition] = useState(0)

  const _trackPosition = _currentIndex * 100

  const _computeTrackStyle = () => {
    if (_swiping) {
      return {
        transform: `translate3d(calc(-${_trackPosition}% + ${_swipePosition}px), 0px, 0px)`
      }
    }
    return {
      transform: `translate3d(-${_trackPosition}%, 0px, 0px)`
    }
  }

  const _onTouchEnd = () => {
    _setInitialSwipePosition(0)
    _setSwiping(false)
    _setSwipePosition(0)
  }

  const _onTouchStart = ev => {
    const xPosition = ev.targetTouches[0].clientX
    _setInitialSwipePosition(xPosition)
    _setSwiping(true)
    _setSwipePosition(0)
  }

  const _onTouchMove = ev => {
    if (!_swiping) return

    const _xPosition = ev.targetTouches[0].clientX
    const _xDistance = _xPosition - _initialSwipePosition
    const _xThreshold = _elementWidth * 0.5
    const _direction = _xDistance > 0 ? 'right' : 'left'
    _setSwipePosition(_xDistance)
    if (_direction === 'left') {
      if (_xDistance < (_xThreshold * -1)) {
        _selectNext()
        return _onTouchEnd()
      }
    } else if (_direction === 'right') {
      if (_xDistance > _xThreshold) {
        _selectPrevious()
        return _onTouchEnd()
      }
    }
  }

  const _selectNext = () => {
    const _finalIndex = items.length - 1
    const _nextIndex = _currentIndex + 1
    if (_nextIndex <= _finalIndex) {
      _setCurrentIndex(_nextIndex)
    }
  }

  const _selectPrevious = () => {
    const _previousIndex = _currentIndex - 1
    if (_previousIndex >= 0) {
      _setCurrentIndex(_previousIndex)
    }
  }

  const _updateCarousel = () => {
    _setElementWidth(_elementRef.current.clientWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', _updateCarousel)

    return () => {
      window.removeEventListener('resize', _updateCarousel)
    }
  }, [])

  useEffect(() => {
    if (!animationDelay) return

    const interval = setInterval(() => {
      _setCurrentIndex(_currentIndex => {
        const _finalIndex = items.length - 1
        let _nextIndex = _currentIndex + 1
        if (_nextIndex > _finalIndex) {
          _nextIndex = 0
        }
        return _nextIndex
      })
    }, animationDelay)
    return () => clearInterval(interval)
  }, [])

  return (
    <div className="carousel">
      <div className="carousel__container">
        <ul
          className="carousel__items"
          onTouchEnd={ _onTouchEnd }
          onTouchMove={ _onTouchMove }
          onTouchStart={ _onTouchStart }
          ref={ _elementRef }
          style={ _computeTrackStyle() }>
          { items.map((item, index) => {
            return (
              <li
                className="carousel__item"
                key={ index }>
                <h4>{ item.title }</h4>
                <p>"{ item.body }"</p>
              </li>
            )
          }) }
        </ul>
      </div>
      <div className="carousel__tabs">
        { items.map((_, index) => {
          return (
            <div
              className="carousel__tab"
              current={ (index === _currentIndex).toString() }
              key={ index }
              onClick={ () => _setCurrentIndex(index) }>
              Select Item
            </div>
          )
        }) }
      </div>
    </div>
  )
}

Carousel.propTypes = {
  animationDelay: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string
    })
  ).isRequired
}

export default Carousel
