import React from 'react'
import PropTypes from 'prop-types'
import NavigationItem from './NavigationItem'

const NavigationList = ({ primaryItems, secondaryItems }) => {
  const displayPrimaryItems = !!primaryItems.length
  const displaySecondaryItems = !!secondaryItems.length
  const pathIsActive = (path) => {
    const pattern = new RegExp(path)
    return pattern.test(window.location.pathname)
  }
  return (
    <div className="navigation-list">
      {
        displayPrimaryItems &&
        <ul className="navigation-items navigation-items--primary">
          {
            primaryItems.map((item, index) => {
              return (
                <NavigationItem
                  active={pathIsActive(item.path)}
                  item={item}
                  key={index}
                />
              )
            })
          }
        </ul>
      }
      {
        displaySecondaryItems &&
        <ul className="navigation-items navigation-items--secondary">
          {
            secondaryItems.map((item, index) => {
              return (
                <NavigationItem
                  active={pathIsActive(item.path)}
                  item={item}
                  key={index}
                />
              )
            })
          }
        </ul>
      }
    </div>
  )
}

NavigationList.defaultProps = {
  primaryItems: [],
  secondaryItems: []
}

NavigationList.propTypes = {
  primaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string
    })
  ),
  secondaryItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string
    })
  )
}

export default NavigationList
