import ReactRailsUJS from 'react_ujs'

  import * as Sentry from '@sentry/browser'
  Sentry.init({
    dsn: "https://9b193a07af4145b7aa0a3d00e1afd3ee@o823981.ingest.sentry.io/5817320"
  })


const componentRequireContext = require.context('components', true);
ReactRailsUJS.useContext(componentRequireContext);
