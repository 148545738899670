import React from 'react'
import PropTypes from 'prop-types'

const BurgerButton = ({ onClick }) => (
  <button className="burger-menu" onClick={onClick} type="button">
    <svg width="32" height="32" viewBox="0 0 32 32">
      <line
        x1="2"
        y1="5"
        x2="30"
        y2="5"
        className="burger-menu__bar burger-menu__bar--top"
      />
      <line
        x1="2"
        y1="16"
        x2="30"
        y2="16"
        className="burger-menu__bar burger-menu__bar--middle"
      />
      <line
        x1="2"
        y1="27"
        x2="30"
        y2="27"
        className="burger-menu__bar burger-menu__bar--bottom"
      />
    </svg>
  </button>
)

BurgerButton.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default BurgerButton
