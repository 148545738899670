import React, { useEffect, useState } from "react";

const COOKIES_STORAGE_KEY = "_giki_notice_viewed";
const COOKIES_ACCEPTED_KEY = "_giki_accept";
const STORAGE_DOMAIN =
  location.hostname === "localhost" ? "localhost" : ".impactscore.app";

const setCookie = name => {
  const _cookieDate = new Date();
  _cookieDate.setFullYear(_cookieDate.getFullYear() + 1);
  document.cookie = `${name}=1;domain=${STORAGE_DOMAIN};path=/;secure=true;SameSite=Strict;expires=${_cookieDate.toUTCString()}`;
};

const deleteCookie = name => {
  document.cookie = `${name}=;domain=${STORAGE_DOMAIN};secure=true;SameSite=Strict;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
};

const CookieNotification = ({ title, body, buttons }) => {
  const _previouslyViewed =
    document.cookie.indexOf(COOKIES_STORAGE_KEY) > -1 || false;

  const [_viewed, _setViewed] = useState(_previouslyViewed);

  const _accept = () => {
    _setViewed(true);
    setCookie(COOKIES_STORAGE_KEY);
    setCookie(COOKIES_ACCEPTED_KEY);
    if (window.loadGA) {
      window.loadGA();
    }
  };

  const _refuse = () => {
    _setViewed(true);
    setCookie(COOKIES_STORAGE_KEY);
    deleteCookie(COOKIES_ACCEPTED_KEY);
  };

  useEffect(() => {
    if (
      document.cookie.indexOf(COOKIES_ACCEPTED_KEY) === -1 &&
      (window.doNotTrack === "1" ||
        navigator.doNotTrack === "yes" ||
        navigator.doNotTrack === "1" ||
        navigator.msDoNotTrack === "1")
    ) {
      _refuse();
    }
  }, []);

  if (_viewed) {
    return null;
  }

  return (
    <div className="cookie-notification">
      <div className="cookie-notification__content">
        <h3 className="h4">{title}</h3>
        <p>{body}</p>
        <div className="cookie-notification__actions">
          <button
            className="button button--small button--c-white"
            onClick={_refuse}
            type="button"
          >
            {buttons.refuse}
          </button>
          <button
            className="button button--small button--c-white"
            onClick={_accept}
            type="button"
          >
            {buttons.accept}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieNotification;
